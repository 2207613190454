import lineLineChart          from "assets/images/chart-images/line-chart.png";
import table      from "assets/images/chart-images/table.png";
import barSimpleBarChart from "assets/images/chart-images/simple-chart.png";
import barHorizontalBarChart from "assets/images/chart-images/horizontal-bar-chart.png";
import mapWorldMap       from "assets/images/chart-images/map/map_world_map.png";

const timePeriodList = [
        // Time period - None
        {id: "%AFTP-L01s", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%AFTP-L01e", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%AFC-M01e", name: "line", chartImage: mapWorldMap, disabled: false},
        {id: "%AFC-M02e", name: "line", chartImage: mapWorldMap, disabled: false},
        // {id: "%AFTP-L02e", name: "line", chartImage: lineLineChart, disabled: false},
        {id: "%AFTP-T01s", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%AFTP-T01e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%AFTP-T02e", name: "table", chartImage: table, v2: "Column", disabled: false},
        {id: "%AFTP-BV01e", name: "simple-bar", chartImage: barSimpleBarChart, disabled: false},
        {id: "%AFTP-BH01e", name: "horizontal-bar", chartImage: barHorizontalBarChart, disabled: false},
];

export default timePeriodList;
