import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import { Provider } from "react-redux";
import store from "./store";
import "./bootstrap/add-refresh-tocken-logic";
import { ToastContainer } from "react-toastify";
import TypeCheck from "utils/type-check";
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";

import "react-toastify/dist/ReactToastify.css";
import "assets/icons/style.css";
import "./index.css";

window.isArray = TypeCheck.isArray;
window.isObject = TypeCheck.isObject;
window.isEmpty = TypeCheck.isEmpty;

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
const client = process.env.REACT_APP_GOOGLE_CLIENT_ID

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId={client}>
      <App />
      <ToastContainer
        toastClassName="toast"
        autoClose={2000}
        position="top-right"
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ top: "90px", right: "8px" }}
      />
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);
